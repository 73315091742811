<template>
  <a href="#" class="brand-item">
    <div class="circle mb-4 rounded-full bg-gray-300 lg:mb-2">
      <div
        class="relative block h-[80px] w-[80px] lg:h-[136px] lg:w-[136px]"
      ></div>
    </div>
    <div class="flex flex-col items-center justify-center text-xs lg:text-sm">
      <div
        class="label mb-2 h-4 w-20 bg-gray-300 capitalize text-gray-300"
      ></div>
    </div>
  </a>
</template>

<script lang="ts">
export default {
  name: "PackageTypeLoading",
};
</script>
