<template>
  <PackageTypeLoading v-if="isLoading" />
  <PackageTypeMain v-else v-bind="props" />
</template>

<script lang="ts" setup>
import { defineAsyncComponent, toRefs } from "vue";
import { type Props as PackageTypeMainProps } from "./PackageTypeMain.vue";
import PackageTypeLoading from "./PackageTypeLoading.vue";

export interface Props extends PackageTypeMainProps {
  isLoading: boolean;
}

const props = defineProps<Props>();
const { isLoading } = toRefs(props);
const PackageTypeMain = defineAsyncComponent(
  () => import("./PackageTypeMain.vue")
);
</script>
<script lang="ts">
export default {
  name: "PackageType",
};
</script>
